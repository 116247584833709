import React, { useState } from "react";
import FirstVisit from "./FirstVisit";
import {
  Grid,
  Button,
  FormControl,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Backdrop,
  Box,
  Modal,
  Fade,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const SecondVisit = () => {
  const [tokenNo, setTokenNo] = useState("");
  const [showSecondVisit, setShowSecondVisit] = useState(true);
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);
  const [patientDetail, getPatientDetail] = useState({});
  let navigate = useNavigate();

  const columns = ["Token No", "Patient Name"];

  const fetchPatientDetails = async () => {
    await fetch(
      process.env.REACT_APP_API_URL +
        "/patient/patientByTokenNo/highestVisit/" +
        tokenNo
    )
      .then((res) => res.json())
      .then((data) => {
        getPatientDetail(data);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const addPatientHandler = async () => {
    patientDetail.visitNo += 1;
    patientDetail.sentToDoctor = 0;
    const newDetail = { online: true };
    getPatientDetail((prevState) => ({
      newDetail,
      ...prevState,
    }));
    await fetch(process.env.REACT_APP_API_URL + "/patient/addRepeatPatient", {
      method: "POST",
      body: JSON.stringify(patientDetail),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        res.json();
      })
      .then(() => {
        alert("Appointment Book Successfully!");
        navigate("/#specialities");
      })
      .catch((err) => console.log(err));
  };

  const handleChange = (e) => {
    setTokenNo(e.target.value);
    setOpen(false);
  };

  const handleChangeToken = () => {
    if (tokenNo !== "") {
      fetchPatientDetails();
      setOpen(true);
    } else {
      alert("Token Number cannot be empty!");
    }
  };

  let patientForm;
  if (showSecondVisit) {
    patientForm = (
      <>
        <Typography
          variant="h5"
          sx={{ mt: 1, color: "#8446a2", textDecoration: "underline" }}
        >
          Registered Patient
        </Typography>
        <Typography variant="body2" sx={{ mt: 1, color: "#ed1713" }}>
          Please Refer Prescription for Token Number
        </Typography>
        <Typography variant="body2" sx={{ mt: 1, color: "#ed1713" }}>
          कृपया टोकन नंबर के लिए प्रिस्क्रिप्शन देखें
        </Typography>
        <br />
        <Grid mt={1}>
          <FormControl>
            <TextField
              required
              id="outlined-required"
              label="Patient Token No."
              value={tokenNo}
              autoFocus
              onChange={handleChange}
              sx={{ width: 300 }}
            />
          </FormControl>
        </Grid>
        <Grid
          mt={2}
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justify="center"
        >
          <Button
            variant="contained"
            style={{ backgroundColor: "#8446a2" }}
            onClick={handleChangeToken}
            size="large"
            className="btn-regular"
          >
            Find Name
          </Button>
        </Grid>
        <Typography variant="body1" sx={{ mt: 3 }}>
          If patient is not registered, please click on First Visit Button
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          यदि रोगी पंजीकृत नहीं है, तो कृपया First Visit बटन पर क्लिक करें
        </Typography>
        <Grid mt={1}>
          <Button
            variant="contained"
            color="success"
            onClick={() => {
              setShowSecondVisit(false);
            }}
          >
            First Visit / पहली मुलाकात
          </Button>
        </Grid>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          slots={{ backdrop: Backdrop }}
          slotProps={{
            backdrop: {
              timeout: 500,
            },
          }}
        >
          <Fade in={open}>
            {patientDetail != null ? (
              <Box sx={style}>
                <Typography variant="h6" component="h2">
                  Patient Details
                </Typography>
                <TableContainer component={Paper} sx={{ boxShadow: 0 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {columns.map((column) => (
                          <TableCell
                            align="center"
                            key={column}
                            sx={{ fontSize: 17 }}
                          >
                            {column}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center">
                          {patientDetail.tokenNo}
                        </TableCell>
                        <TableCell align="center">
                          {patientDetail.patientName}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
                <Box sx={{ marginLeft: "auto", marginRight: "auto" }}>
                  <Button
                    color="success"
                    onClick={addPatientHandler}
                    variant="contained"
                    sx={{ mt: 3, paddingLeft: 5, paddingRight: 5 }}
                  >
                    Book Appointment / बुक अपॉइंटमेंट
                  </Button>

                  <Button
                    color="error"
                    onClick={handleClose}
                    variant="contained"
                    sx={{ mt: 2, paddingLeft: 2,paddingRight:3}}
                  >
                    Change Token Number / टोकन नंबर बदलें
                  </Button>
                </Box>
                <Typography sx={{ mt: 2 }}>
                  यदि आपका नाम सही है तो कृपया बुक अपॉइंटमेंट पर क्लिक करें या
                  नाम गलत है कृपया चेंज टोकन नंबर पर क्लिक करें
                </Typography>
              </Box>
            ) : (
              <Box sx={style}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Patient Details
                </Typography>
                <Typography sx={{ mt: 2 }}>No Patient Found!</Typography>
                <Typography sx={{ mt: 2 }}>
                  Please Check Token Number is correct or not else you can
                  re-register.
                </Typography>
                <Typography sx={{ mt: 2 }}>Thanks you!</Typography>
              </Box>
            )}
          </Fade>
        </Modal>
      </>
    );
  } else {
    patientForm = <FirstVisit />;
  }
  return patientForm;
};

export default SecondVisit;
