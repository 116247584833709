import React, { useState } from "react";
import { Button } from "@mui/material";
import FirstVisit from "./FirstVisit";
import SecondVisit from "./SecondVisit";

function BookAppointmentNew() {
  const [page, setPage] = useState("");
  const [showNewButton, setShowNewButton] = useState(true);
  const [showAlreadyButton, setShowAlreadyButton] = useState(true);

  let visitFlag;

  const load = (e) => {
    setShowAlreadyButton(!showAlreadyButton);
    setShowNewButton(!showNewButton);
    setPage(e.target.name);
  };

  if (page === "new") {
    visitFlag = <FirstVisit />;
  } else if (page === "already") {
    visitFlag = <SecondVisit />;
  }

  return (
    <div className="container-fluid">
      <div className="row ">
        <div className="col-md-4 text-center"></div>
        <div className="col-md-4 text-center">
          <h1 style={{ color: "#8446a2" }}>Book an Appointment</h1>
          <hr />
          {visitFlag}
          {showNewButton && (
            <Button
              style={{
                paddingTop: 10,
                paddingBottom: 10,
                paddingLeft: 10,
                paddingRight: 10,
                margin: 10,
              }}
              size="large"
              color="success"
              variant="contained"
              onClick={load}
              name="new"
            >
              First Visit / पहली मुलाकात
            </Button>
          )}
          <br />
          {showAlreadyButton && (
            <Button
              style={{
                padding: 10,
                margin: 10,
                paddingLeft: 10,
                paddingRight: 10,
              }}
              variant="contained"
              size="large"
              color="secondary"
              onClick={load}
              name="already"
            >
              Next Visit / अगली मुलाकात
            </Button>
          )}
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
}
export default BookAppointmentNew;
