const Images={
    Beforeimage1thumb:require('./Before/1-small.png'),
    Beforeimage1:require('./Before/1.jpg'),
    Beforeimage2thumb:require('./Before/2-small.png'),
    Beforeimage2:require('./Before/2.jpg'),
    Beforeimage3thumb:require('./Before/3-small.png'),
    Beforeimage3:require('./Before/3.jpg'),
    Afterimage1thumb:require('./After/1-small.png'),
    Afterimage1:require('./After/1.jpg'),
    Afterimage2thumb:require('./After/2-small.png'),
    Afterimage2:require('./After/2.jpg'),
    Afterimage3thumb:require('./After/3-small.png'),
    Afterimage3:require('./After/3.jpg'),
    
}
export default Images;